import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.PLAIN_HTML,
      present_height: 120,
      wb_position: 0,
      wb_html_code__long_text__: `<br><p>${trans('wb_html_code')}</p><br>`
    },
    images: []
  }
}
