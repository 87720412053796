





















import SidebarDetails from '@/calendesk/models/SidebarDetails'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import CategorySection from '@/calendesk/models/CategorySection'
import { SidebarDetailsType } from '../sidebarDetails/SidebarDetailsType'
import { SectionType, SidebarType } from '@/calendesk/models/BuilderTypes'
import AvailableSections from '@/builder/sections/section/AvailableSections'
import { pushEvent } from '@/lib/calendesk-js-library/tools/helpers'

export default Vue.extend({
  name: 'SectionCategories',
  data () {
    return {
      selectedItem: null
    }
  },
  computed: {
    ...mapGetters({
      sidebar: 'sidebar/getSidebar'
    }),
    categories () {
      if (this.sidebar.type === SidebarType.REPLACE_NAVBAR) {
        return AvailableSections().filter(section => section.type === SectionType.NAVBAR)
      }

      if (this.sidebar.type === SidebarType.REPLACE_FOOTER) {
        return AvailableSections().filter(section => section.type === SectionType.FOOTER)
      }

      return AvailableSections().filter(section => section.type === SectionType.SECTION)
    }
  },
  methods: {
    ...mapActions({
      openSidebarDetails: 'sidebar/openSidebarDetails'
    }),
    selectCategory (item: CategorySection) {
      pushEvent('wbSelectSectionCategory')

      let sidebarDetailsType = SidebarDetailsType.ADD_SECTION

      switch (this.sidebar.type) {
        case SidebarType.REPLACE_NAVBAR:
          sidebarDetailsType = SidebarDetailsType.REPLACE_NAVBAR
          break
        case SidebarType.REPLACE_FOOTER:
          sidebarDetailsType = SidebarDetailsType.REPLACE_FOOTER
          break
      }

      this.openSidebarDetails(new SidebarDetails(true, sidebarDetailsType, item))
    }
  }
})
