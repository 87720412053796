import { FooterSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import StoreController from '@/calendesk/controllers/StoreController'

export default (): SectionConfiguration => {
  return {
    type: SectionType.FOOTER,
    configuration: {
      component_id: FooterSectionType.FOOTER5,
      present_height: 55,
      wb_company_name__text__: StoreController.appConfiguration()?.companyName
    },
    images: []
  }
}
