import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.CTA_1,
      present_height: 250,
      wb_height__style_height__: 250,
      wb_min_height: 100,
      wb_position: 0,
      wb_description__html_text__: `<h4 class="text-h4" style="text-align: center">${trans('wb_dummy_text_2')}</h4>`,
      wb_subtitle__text__: trans('wb_dummy_text_title'),
      wb_align_center__checkbox__: true,
      wb_button__cta_button__: {
        title: trans('wb_dummy_text_more'),
        page_id: null,
        outlined: false,
        rounded: false,
        size: 'x-large',
        elevation: 0,
        is_functional: false
      },
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: false
    },
    images: []
  }
}
