import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { CreatorDefaultBackground } from '@/calendesk/models/Creator/CreatorDefaultBackground'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.FEATURE_6,
      present_height: 500,
      wb_position: 0,
      wb_height__style_height__: 500,
      wb_title__text__: trans('wb_dummy_text_2'),
      wb_subtitle__text__: trans('wb_dummy_text_services'),
      wb_buttons__array_cta_buttons__: [
        {
          title: trans('wb_dummy_text_more'),
          page_id: '',
          outlined: true,
          rounded: true,
          size: 'x-large',
          elevation: 0,
          is_functional: false
        },
        {
          title: trans('wb_dummy_text_employees'),
          page_id: '',
          outlined: false,
          rounded: true,
          size: 'x-large',
          elevation: 0,
          is_functional: false
        }
      ]
    },
    images: [
      {
        slug: 'bg',
        type: SectionImageType.IMAGE,
        name: CreatorDefaultBackground.getForText1()
      }
    ]
  }
}
