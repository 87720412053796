import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.FEATURE_10,
      present_height: 500,
      wb_position: 0,
      wb_description__html_text__: `<h3 class="text-h3">${trans('wb_dummy_text_2')}</h3><br><br><p>${trans('wb_dummy_text_1')}</p><p>${trans('wb_dummy_text_1')}</p>`
    },
    images: [
      {
        slug: 'bg',
        type: SectionImageType.IMAGE,
        name: CreatorDefaultImage.getImage(1)
      }
    ]
  }
}
