import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.HEADER_12,
      wb_cols_cols__cols_cols__: 12,
      wb_cols_sm__cols_sm__: 12,
      wb_cols_md__cols_md__: 12,
      wb_cols_lg__cols_lg__: 12,
      wb_cols_xl__cols_xl__: 12,
      present_height: 300,
      wb_height__style_height__: 300,
      wb_min_height: 50,
      wb_position: 0,
      wb_text__html_text__: `<h1 class="text-h1" style="text-align: center">${trans('wb_dummy_text_2')}</h1>`,
      wb_align_center__checkbox__: true,
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: true
    },
    images: []
  }
}
