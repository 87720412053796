import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.OTHER_2,
      present_height: 150,
      wb_height__style_height__: 50,
      wb_min_height: 50,
      wb_position: 0,
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: true
    },
    images: []
  }
}
