import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.STATIC_4,
      present_height: 1000,
      wb_position: 0,
      wb_redirect_after_signup__page_id__: null,
      wb_button__cta_button__: {
        title: trans('sign_up'),
        page_id: null,
        outlined: false,
        rounded: false,
        size: 'x-large',
        elevation: 0,
        is_functional: true
      },
      wb_gdpr_fields__gdpr_fields__: [
        {
          name: trans('wb_dummy_gdpr_field_name'),
          label: trans('wb_dummy_gdpr_field'),
          required: true
        }
      ],
      wb_invert_colors__checkbox__: false
    },
    images: []
  }
}
