import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'
import { AlignType } from '@/calendesk/models/AlignType'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import { RoundType } from '@/calendesk/models/RoundType'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.FLEXIBLE_1,
      wb_position: 0,
      present_height: 450,
      wb_height__style_height__: 450,
      wb_min_height: 50,
      wb_text__html_text__: `${trans('wb_dummy_text_1')}<br><br><h4 class="text-h4">${trans('wb_dummy_text_title')}</h4><p>${trans('wb_dummy_text_1')} ${trans('wb_dummy_text_1')}</p>`,
      wb_buttons__array_cta_buttons__: [
        {
          title: trans('wb_dummy_text_more'),
          page_id: '',
          outlined: true,
          rounded: true,
          size: 'x-large',
          elevation: 0,
          is_functional: false
        },
        {
          title: trans('wb_dummy_text_employees'),
          page_id: '',
          outlined: false,
          rounded: true,
          size: 'x-large',
          elevation: 0,
          is_functional: false
        }
      ],
      wb_buttons_position__select__: {
        type: BuilderSelectType.BUTTONS_POSITIONS,
        value: AlignType.BOTTOM_CENTER
      },
      wb_image_position__select__: {
        type: BuilderSelectType.IMAGE_POSITIONS,
        value: AlignType.TOP_LEFT
      },
      wb_image_width__number__: 200,
      wb_image_height__number__: 400,
      wb_image_rounded__select__: {
        type: BuilderSelectType.IMAGE_ROUNDED,
        value: RoundType.MD
      },
      wb_image_hide__checkbox__: false,
      wb_image_hide_on_mobile__checkbox__: false,
      wb_image_contain__checkbox__: false,
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: false
    },
    images: [
      {
        slug: 'img_1',
        type: SectionImageType.IMAGE,
        name: CreatorDefaultImage.getImage(3)
      }
    ]
  }
}
