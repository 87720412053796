import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.FEATURE_2,
      present_height: 600,
      wb_position: 0,
      wb_title__text__: trans('wb_dummy_text_2'),
      wb_subtitle__text__: trans('wb_dummy_text_3'),
      wb_description__html_text__: trans('wb_dummy_text_1'),
      wb_buttons__array_cta_buttons__: [
        {
          title: trans('wb_dummy_text_more'),
          page_id: '',
          outlined: true,
          rounded: true,
          size: 'large',
          elevation: 0,
          is_functional: false
        }
      ]
    },
    images: [
      {
        slug: 'bg',
        type: SectionImageType.IMAGE,
        name: CreatorDefaultImage.getImage(3)
      }
    ]
  }
}
