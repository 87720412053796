import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { v4 as uuidv4 } from 'uuid'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import { RoundType } from '@/calendesk/models/RoundType'

const uuids = [
  uuidv4(),
  uuidv4(),
  uuidv4(),
  uuidv4()
]

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.FLEXIBLE_REVIEW_2,
      present_height: 550,
      has_internal_images: true,
      has_internal_html_content: true,
      has_section_feature_subtitle: false,
      wb_position: 0,
      wb_text__html_text__: `<h3 class="text-h3" style="text-align: center;">${trans('wb_dummy_text_reviews_title')}</h3><br><br>`,
      wb_column_width__number__: 25,
      wb_gallery_distance__number__: 20,
      wb_image_height__number__: 200,
      wb_image_margin_bottom__number__: 20,
      wb_text_margin_bottom__number__: 20,
      wb_image_rounded__select__: {
        type: BuilderSelectType.IMAGE_ROUNDED,
        value: RoundType.XL
      },
      wb_image_hide__checkbox__: false,
      wb_image_hide_on_mobile__checkbox__: false,
      wb_image_contain__checkbox__: false,
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: true,
      wb_features__array_section_features__: [
        {
          uuid: uuids[0],
          description: `<p style="text-align: center;">${trans('wb_dummy_text_review_example')}</p>`,
          buttons: [
            {
              title: trans('wb_dummy_text_more'),
              page_id: '',
              outlined: true,
              rounded: true,
              size: 'medium',
              elevation: 0,
              is_functional: false
            }
          ]
        },
        {
          uuid: uuids[1],
          description: `<p style="text-align: center;">${trans('wb_dummy_text_review_example')}</p>`,
          buttons: [
            {
              title: trans('wb_dummy_text_more'),
              page_id: '',
              outlined: true,
              rounded: true,
              size: 'medium',
              elevation: 0,
              is_functional: false
            }
          ]
        },
        {
          uuid: uuids[2],
          description: `<p style="text-align: center;">${trans('wb_dummy_text_review_example')}</p>`,
          buttons: [
            {
              title: trans('wb_dummy_text_more'),
              page_id: '',
              outlined: true,
              rounded: true,
              size: 'medium',
              elevation: 0,
              is_functional: false
            }
          ]
        },
        {
          uuid: uuids[3],
          description: `<p style="text-align: center;">${trans('wb_dummy_text_review_example')}</p>`,
          buttons: [
            {
              title: trans('wb_dummy_text_more'),
              page_id: '',
              outlined: true,
              rounded: true,
              size: 'medium',
              elevation: 0,
              is_functional: false
            }
          ]
        }
      ]
    },
    images: [
      {
        slug: uuids[0],
        type: SectionImageType.INTERNAL,
        name: '/demo/c0-employee-1.jpg'
      },
      {
        slug: uuids[1],
        type: SectionImageType.INTERNAL,
        name: '/demo/c0-employee-2.jpg'
      },
      {
        slug: uuids[2],
        type: SectionImageType.INTERNAL,
        name: '/demo/c0-employee-3.jpg'
      },
      {
        slug: uuids[3],
        type: SectionImageType.INTERNAL,
        name: '/demo/c0-employee-4.jpg'
      }
    ]
  }
}
