import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.NEWSLETTER_8,
      present_height: 100,
      wb_height__style_height__: 100,
      wb_min_height: 100,
      wb_position: 0,
      wb_title__text__: trans('wb_dummy_text_newsletter_6'),
      wb_newsletter_input_placeholder__text__: trans('wb_dummy_text_newsletter_4'),
      wb_newsletter_success_message__long_text__: trans('wb_dummy_text_newsletter_5'),
      wb_button__cta_button__: {
        title: trans('wb_dummy_text_newsletter_3'),
        page_id: null,
        outlined: false,
        rounded: false,
        size: 'x-large',
        elevation: 0,
        is_functional: true
      },
      wb_invert_colors__checkbox__: false
    },
    images: []
  }
}
