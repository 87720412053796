import { SectionCategoryTypeId, SectionType } from '@/calendesk/models/BuilderTypes'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

import Footer1Configuration from '@/builder/sections/footer/footer1/Footer1Configuration'
import Feature1Configuration from '@/builder/sections/section/features/feature1/Feature1Configuration'
import Feature2Configuration from '@/builder/sections/section/features/feature2/Feature2Configuration'
import Feature4Configuration from '@/builder/sections/section/features/feature4/Feature4Configuration'
import Feature5Configuration from '@/builder/sections/section/features/feature5/Feature5Configuration'
import Feature6Configuration from '@/builder/sections/section/features/feature6/Feature6Configuration'
import Feature7Configuration from '@/builder/sections/section/features/feature7/Feature7Configuration'
import RegulationsConfiguration from '@/builder/sections/section/static/regulations/RegulationsConfiguration'
import PrivacyPolicyConfiguration from '@/builder/sections/section/static/privacyPolicy/PrivacyPolicyConfiguration'
import Feature8Configuration from '@/builder/sections/section/features/feature8/Feature8Configuration'
import Feature9Configuration from '@/builder/sections/section/features/feature9/Feature9Configuration'
import Footer2Configuration from '@/builder/sections/footer/footer2/Footer2Configuration'
import Footer3Configuration from '@/builder/sections/footer/footer3/Footer3Configuration'
import Footer4Configuration from '@/builder/sections/footer/footer4/Footer4Configuration'
import Footer5Configuration from '@/builder/sections/footer/footer5/Footer5Configuration'
import Footer6Configuration from '@/builder/sections/footer/footer6/Footer6Configuration'
import Footer7Configuration from '@/builder/sections/footer/footer7/Footer7Configuration'
import Footer8Configuration from '@/builder/sections/footer/footer8/Footer8Configuration'
import Newsletter1Configuration from '@/builder/sections/section/newsletter/newsletter1/Newsletter1Configuration'
import Newsletter2Configuration from '@/builder/sections/section/newsletter/newsletter2/Newsletter2Configuration'
import Newsletter3Configuration from '@/builder/sections/section/newsletter/newsletter3/Newsletter3Configuration'
import Newsletter4Configuration from '@/builder/sections/section/newsletter/newsletter4/Newsletter4Configuration'
import Newsletter5Configuration from '@/builder/sections/section/newsletter/newsletter5/Newsletter5Configuration'
import Newsletter6Configuration from '@/builder/sections/section/newsletter/newsletter6/Newsletter6Configuration'
import Newsletter7Configuration from '@/builder/sections/section/newsletter/newsletter7/Newsletter7Configuration'
import Newsletter8Configuration from '@/builder/sections/section/newsletter/newsletter8/Newsletter8Configuration'
import Header9Configuration from '@/builder/sections/section/header/header9/Header9Configuration'
import Header10Configuration from '@/builder/sections/section/header/header10/Header10Configuration'
import Text13Configuration from '@/builder/sections/section/text/text13/Text13Configuration'
import Header11Configuration from '@/builder/sections/section/header/header11/Header11Configuration'
import Feature10Configuration from '@/builder/sections/section/features/feature10/Feature10Configuration'
import Cta1Configuration from '@/builder/sections/section/cta/cta1/Cta1Configuration'
import Feature11Configuration from '@/builder/sections/section/features/feature11/Feature11Configuration'
import Header12Configuration from '@/builder/sections/section/header/header12/Header12Configuration'
import Text14Configuration from '@/builder/sections/section/text/text14/Text14Configuration'
import Feature12Configuration from '@/builder/sections/section/features/feature12/Feature12Configuration'
import Feature13Configuration from '@/builder/sections/section/features/feature13/Feature13Configuration'
import Cta2Configuration from '@/builder/sections/section/cta/cta2/Cta2Configuration'
import Cta3Configuration from '@/builder/sections/section/cta/cta3/Cta3Configuration'
import Other1Configuration from '@/builder/sections/section/other/other1/Other1Configuration'
import Flexible1Configuration1 from '@/builder/sections/section/flexible/Flexible1Configuration1'
import Flexible1Configuration2 from '@/builder/sections/section/flexible/Flexible1Configuration2'
import Flexible1Configuration3 from '@/builder/sections/section/flexible/Flexible1Configuration3'
import Flexible1Configuration4 from '@/builder/sections/section/flexible/Flexible1Configuration4'
import Flexible1Configuration5 from '@/builder/sections/section/flexible/Flexible1Configuration5'
import Flexible1Configuration6 from '@/builder/sections/section/flexible/Flexible1Configuration6'
import Flexible1Configuration7 from '@/builder/sections/section/flexible/Flexible1Configuration7'
import Flexible1Configuration9 from '@/builder/sections/section/flexible/Flexible1Configuration9'
import Flexible1Configuration8 from '@/builder/sections/section/flexible/Flexible1Configuration8'
import Flexible1Configuration10 from '@/builder/sections/section/flexible/Flexible1Configuration10'
import Background1Configuration1 from '@/builder/sections/section/background/Background1Configuration1'
import Background2Configuration2 from '@/builder/sections/section/background/Background2Configuration2'
import FlexibleGallery1Configuration1 from '@/builder/sections/section/flexibleGallery/FlexibleGallery1Configuration1'
import FlexibleGallery1Configuration2 from '@/builder/sections/section/flexibleGallery/FlexibleGallery1Configuration2'
import FlexibleGallery1Configuration3 from '@/builder/sections/section/flexibleGallery/FlexibleGallery1Configuration3'
import FlexibleGallery1Configuration4 from '@/builder/sections/section/flexibleGallery/FlexibleGallery1Configuration4'
import FlexibleGallery1Configuration5 from '@/builder/sections/section/flexibleGallery/FlexibleGallery1Configuration5'
import FlexibleGallery1Configuration6 from '@/builder/sections/section/flexibleGallery/FlexibleGallery1Configuration6'
import FlexibleGallery1Configuration7 from '@/builder/sections/section/flexibleGallery/FlexibleGallery1Configuration7'
import FlexibleNavbar1Configuration1 from '@/builder/sections/navbar/flexibleNavbar/FlexibleNavbar1Configuration1'
import FlexibleNavbar1Configuration2 from '@/builder/sections/navbar/flexibleNavbar/FlexibleNavbar1Configuration2'
import FlexibleNavbar1Configuration3 from '@/builder/sections/navbar/flexibleNavbar/FlexibleNavbar1Configuration3'
import FlexibleNavbar1Configuration4 from '@/builder/sections/navbar/flexibleNavbar/FlexibleNavbar1Configuration4'
import FlexibleNavbar1Configuration5 from '@/builder/sections/navbar/flexibleNavbar/FlexibleNavbar1Configuration5'
import FlexibleNavbar1Configuration6 from '@/builder/sections/navbar/flexibleNavbar/FlexibleNavbar1Configuration6'
import FlexibleNavbar1Configuration7 from '@/builder/sections/navbar/flexibleNavbar/FlexibleNavbar1Configuration7'
import FlexibleNavbar1Configuration8 from '@/builder/sections/navbar/flexibleNavbar/FlexibleNavbar1Configuration8'
import FlexibleBooking2Configuration from '@/builder/sections/section/flexibleBooking/FlexibleBooking2Configuration'
import PlainHtml1Configuration from '@/builder/sections/section/plainHtml/PlainHtml1Configuration'
import FlexibleGallery2Configuration1 from '@/builder/sections/section/flexibleGallery/FlexibleGallery2Configuration1'
import FlexibleMap1Configuration from '@/builder/sections/section/flexibleMap/FlexibleMap1Configuration'
import FlexibleReviews1Configuration1 from '@/builder/sections/section/flexibleReview/FlexibleReviews1Configuration1'
import FlexibleReviews1Configuration2 from '@/builder/sections/section/flexibleReview/FlexibleReviews1Configuration2'
import FlexibleReviews1Configuration3 from '@/builder/sections/section/flexibleReview/FlexibleReviews1Configuration3'
import FlexibleReviews1Configuration4 from '@/builder/sections/section/flexibleReview/FlexibleReviews1Configuration4'
import FlexibleReviews1Configuration5 from '@/builder/sections/section/flexibleReview/FlexibleReviews1Configuration5'
import FlexibleReviews1Configuration6 from '@/builder/sections/section/flexibleReview/FlexibleReviews1Configuration6'
import LoginConfiguration1 from '@/builder/sections/section/static/login/LoginConfiguration1'
import SignupConfiguration1 from '@/builder/sections/section/static/signup/SignupConfiguration1'
import FlexibleForm1Configuration1 from '@/builder/sections/section/flexibleForm/FlexibleForm1Configuration1'
import FlexibleForm1Configuration2 from '@/builder/sections/section/flexibleForm/FlexibleForm1Configuration2'
import FlexibleForm1Configuration3 from '@/builder/sections/section/flexibleForm/FlexibleForm1Configuration3'
import FlexibleBooking3Configuration from '@/builder/sections/section/flexibleBooking/FlexibleBooking3Configuration'
import FlexibleBooking4Configuration from '@/builder/sections/section/flexibleBooking/FlexibleBooking4Configuration'
import FlexibleReviews2Configuration1 from '@/builder/sections/section/flexibleReview/FlexibleReviews2Configuration1'
import FlexibleReviews2Configuration2 from '@/builder/sections/section/flexibleReview/FlexibleReviews2Configuration2'
import FlexibleReviews2Configuration3 from '@/builder/sections/section/flexibleReview/FlexibleReviews2Configuration3'
import FlexibleReviews2Configuration4 from '@/builder/sections/section/flexibleReview/FlexibleReviews2Configuration4'
import FlexibleReviews2Configuration5 from '@/builder/sections/section/flexibleReview/FlexibleReviews2Configuration5'
import FlexibleReviews2Configuration6 from '@/builder/sections/section/flexibleReview/FlexibleReviews2Configuration6'
import Other2Configuration from '@/builder/sections/section/other/other2/Other2Configuration'
import CalendarV2Configuration from '@/builder/sections/section/calendars/CalendarV2Configuration'

export default () => {
  return [
    {
      type: SectionType.SECTION,
      category: SectionCategoryTypeId.BOOKING,
      name: trans('wb_category_section_booking'),
      sections: [
        CalendarV2Configuration(),
        FlexibleBooking3Configuration(),
        FlexibleBooking2Configuration(),
        FlexibleBooking4Configuration()
      ]
    },
    {
      type: SectionType.SECTION,
      category: SectionCategoryTypeId.FEATURE,
      name: trans('wb_category_section_feature'),
      sections: [
        Flexible1Configuration1(),
        Flexible1Configuration2(),
        Flexible1Configuration3(),
        Flexible1Configuration4(),
        Flexible1Configuration5(),
        Flexible1Configuration6(),
        Flexible1Configuration7(),
        Flexible1Configuration8(),
        Flexible1Configuration9(),
        Flexible1Configuration10(),

        FlexibleReviews2Configuration1(),
        FlexibleReviews2Configuration4(),
        FlexibleReviews2Configuration2(),
        FlexibleReviews2Configuration5(),
        FlexibleReviews2Configuration3(),
        FlexibleReviews2Configuration6(),

        FlexibleReviews1Configuration1(),
        FlexibleReviews1Configuration2(),
        FlexibleReviews1Configuration3(),
        FlexibleReviews1Configuration4(),
        FlexibleReviews1Configuration5(),
        FlexibleReviews1Configuration6(),

        Text14Configuration(),
        Text13Configuration(),
        Header12Configuration(),
        Header11Configuration(),
        Header10Configuration(),
        Header9Configuration(),

        // Old
        Feature13Configuration(),
        Feature12Configuration(),
        Feature11Configuration(),
        Feature10Configuration(),
        Feature1Configuration(),
        Feature2Configuration(),
        Feature4Configuration(),
        Feature5Configuration(),
        Feature6Configuration(),
        Feature7Configuration(),
        Feature8Configuration(),
        Feature9Configuration()
      ]
    },
    {
      type: SectionType.SECTION,
      category: SectionCategoryTypeId.GALLERY,
      name: trans('wb_category_section_photo_gallery'),
      sections: [
        FlexibleGallery1Configuration1(),
        FlexibleGallery1Configuration2(),
        FlexibleGallery1Configuration3(),
        FlexibleGallery1Configuration4(),
        FlexibleGallery1Configuration5(),
        FlexibleGallery1Configuration6(),
        FlexibleGallery1Configuration7(),
        FlexibleGallery2Configuration1(),

        Background2Configuration2(),
        Background1Configuration1()
      ]
    },
    {
      type: SectionType.NAVBAR,
      category: SectionCategoryTypeId.NAVBAR_1,
      name: trans('wb_category_section_navbar_1'),
      sections: [
        FlexibleNavbar1Configuration1(),
        FlexibleNavbar1Configuration2(),
        FlexibleNavbar1Configuration3(),
        FlexibleNavbar1Configuration4()
      ]
    },
    {
      type: SectionType.NAVBAR,
      category: SectionCategoryTypeId.NAVBAR_2,
      name: trans('wb_category_section_navbar_2'),
      sections: [
        FlexibleNavbar1Configuration5(),
        FlexibleNavbar1Configuration6(),
        FlexibleNavbar1Configuration7(),
        FlexibleNavbar1Configuration8()
      ]
    },
    {
      type: SectionType.FOOTER,
      category: SectionCategoryTypeId.FOOTER,
      name: trans('wb_category_section_footer'),
      sections: [
        Footer1Configuration(),
        Footer2Configuration(),
        Footer3Configuration(),
        Footer4Configuration(),
        Footer5Configuration(),
        Footer6Configuration(),
        Footer7Configuration(),
        Footer8Configuration()
      ]
    },
    {
      type: SectionType.SECTION,
      category: SectionCategoryTypeId.FORM,
      name: trans('wb_category_section_form'),
      sections: [
        FlexibleForm1Configuration1(),
        FlexibleForm1Configuration2(),
        FlexibleForm1Configuration3()
      ]
    },
    {
      type: SectionType.SECTION,
      category: SectionCategoryTypeId.NEWSLETTER,
      name: trans('wb_category_section_newsletter'),
      sections: [
        Newsletter1Configuration(),
        Newsletter2Configuration(),
        Newsletter3Configuration(),
        Newsletter4Configuration(),
        Newsletter5Configuration(),
        Newsletter6Configuration(),
        Newsletter7Configuration(),
        Newsletter8Configuration()
      ]
    },
    {
      type: SectionType.SECTION,
      category: SectionCategoryTypeId.CTA,
      name: trans('wb_category_section_cta'),
      sections: [
        Cta1Configuration(),
        Cta2Configuration(),
        Cta3Configuration()
      ]
    },
    {
      type: SectionType.SECTION,
      category: SectionCategoryTypeId.OTHER,
      name: trans('wb_category_section_other'),
      sections: [
        Other1Configuration(),
        Other2Configuration(),
        PlainHtml1Configuration(),
        FlexibleMap1Configuration(),
        LoginConfiguration1(),
        SignupConfiguration1(),
        RegulationsConfiguration(),
        PrivacyPolicyConfiguration()
      ]
    }
  ]
}
