import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'
import StoreController from '@/calendesk/controllers/StoreController'

export class CreatorDefaultBackground extends CreatorDefaultImage {
  public static getDark (): string {
    return this.getImage(6)
  }

  public static getLight (): string {
    return this.getImage(5)
  }

  public static getForText1 (): string {
    if (StoreController.isText1Dark()) {
      return this.getLight()
    }

    return this.getDark()
  }

  public static getForText2 (): string {
    if (StoreController.isText2Dark()) {
      return this.getLight()
    }

    return this.getDark()
  }
}
