import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import { AlignType } from '@/calendesk/models/AlignType'
import { RoundType } from '@/calendesk/models/RoundType'
import { GalleryStyleType } from '@/calendesk/models/GalleryStyleType'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.FLEXIBLE_GALLERY_4,
      has_portfolio_images: true,
      wb_position: 0,
      present_height: 450,
      wb_height__style_height__: 450,
      wb_gallery_height__number__: 400,
      wb_gallery_width__number__: 50,
      wb_gallery_distance__number__: 0,
      wb_min_height: 50,
      wb_text__html_text__: `<h3 class="text-h3">${trans('wb_dummy_text_2')}</h3><br><br><p>${trans('wb_dummy_text_1')}</p>`,
      wb_hide_delimiters__checkbox__: false,
      wb_hide_arrows__checkbox__: false,
      wb_interval__number__: 6000,
      wb_gallery_image_number__number__: 2,
      wb_gallery_mobile_image_number__number__: 1,
      wb_cycle__checkbox__: true,
      wb_image_contain__checkbox__: false,
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: false,
      wb_image_hide__checkbox__: false,
      wb_image_hide_on_mobile__checkbox__: false,
      wb_image_zoom_on_click__checkbox__: true,
      wb_image_position__select__: {
        type: BuilderSelectType.IMAGE_POSITIONS,
        value: AlignType.TOP_LEFT
      },
      wb_buttons_position__select__: {
        type: BuilderSelectType.BUTTONS_POSITIONS,
        value: AlignType.BOTTOM_CENTER
      },
      wb_image_rounded__select__: {
        type: BuilderSelectType.IMAGE_ROUNDED,
        value: RoundType.ZERO
      },
      wb_gallery_style__select__: {
        type: BuilderSelectType.GALLERY_STYLE,
        value: GalleryStyleType.STYLE_2
      },
      wb_buttons__array_cta_buttons__: [
        {
          title: trans('wb_dummy_text_more'),
          page_id: '',
          outlined: true,
          rounded: true,
          size: 'x-large',
          elevation: 0,
          is_functional: false
        },
        {
          title: trans('wb_dummy_text_employees'),
          page_id: '',
          outlined: false,
          rounded: true,
          size: 'x-large',
          elevation: 0,
          is_functional: false
        }
      ]
    },
    images: [
      {
        slug: 'pi_1',
        type: SectionImageType.PORTFOLIO,
        name: CreatorDefaultImage.getImage(1)
      },
      {
        slug: 'pi_2',
        type: SectionImageType.PORTFOLIO,
        name: CreatorDefaultImage.getImage(2)
      },
      {
        slug: 'pi_3',
        type: SectionImageType.PORTFOLIO,
        name: CreatorDefaultImage.getImage(3)
      },
      {
        slug: 'pi_4',
        type: SectionImageType.PORTFOLIO,
        name: CreatorDefaultImage.getImage(4)
      }
    ]
  }
}
