import { FooterSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import StoreController from '@/calendesk/controllers/StoreController'

export default (): SectionConfiguration => {
  return {
    type: SectionType.FOOTER,
    configuration: {
      component_id: FooterSectionType.FOOTER3,
      present_height: 100,
      wb_company_name__text__: StoreController.appConfiguration()?.companyName,
      wb_page_list__page_ids__: []
    },
    images: []
  }
}
