import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.BACKGROUND_1,
      wb_position: 0,
      present_height: 300,
      wb_height__style_height__: 300,
      wb_min_height: 50,
      wb_image_width__number__: null,
      wb_image_height__number__: 300,
      wb_image_contain__checkbox__: false,
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: false
    },
    images: [
      {
        slug: 'img_1',
        type: SectionImageType.IMAGE,
        name: CreatorDefaultImage.getImage(1)
      }
    ]
  }
}
