import { FooterSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import StoreController from '@/calendesk/controllers/StoreController'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

export default (): SectionConfiguration => {
  const address = StoreController.appConfiguration()?.companyAddress
  const email = StoreController.appConfiguration()?.companyEmail
  const phone = StoreController.appConfiguration()?.companyPhone

  return {
    type: SectionType.FOOTER,
    configuration: {
      component_id: FooterSectionType.FOOTER1,
      present_height: 400,
      wb_company_address__text__: address || trans('wb_default_address'),
      wb_company_email__text__: email || trans('wb_default_email'),
      wb_company_phone__text__: phone || trans('wb_default_phone'),
      wb_menu_title__text__: trans('wb_default_menu_title'),
      wb_account_title__text__: trans('wb_default_profile_title'),
      wb_company_name__text__: StoreController.appConfiguration()?.companyName,
      wb_facebook__url__: StoreController.appConfiguration()?.facebook,
      wb_instagram__url__: StoreController.appConfiguration()?.instagram,
      wb_twitter__url__: StoreController.appConfiguration()?.twitter,
      wb_youtube__url__: StoreController.appConfiguration()?.youtube,
      wb_menu_column_1__page_ids__: [],
      wb_menu_column_2__page_ids__: []
    },
    images: []
  }
}
