import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.FEATURE_5,
      wb_position: 0,
      present_height: 600,
      wb_title__text__: trans('wb_dummy_text_2'),
      wb_description__html_text__: trans('wb_dummy_text_1')
    },
    images: [
      {
        slug: 'bg',
        type: SectionImageType.IMAGE,
        name: CreatorDefaultImage.getImage(2)
      }
    ]
  }
}
