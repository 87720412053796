import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.TEXT_14,
      wb_position: 0,
      wb_cols_cols__cols_cols__: 12,
      wb_cols_sm__cols_sm__: 12,
      wb_cols_md__cols_md__: 12,
      wb_cols_lg__cols_lg__: 12,
      wb_cols_xl__cols_xl__: 12,
      present_height: 300,
      wb_height__style_height__: 300,
      wb_min_height: 50,
      wb_text__html_text__: `${trans('wb_dummy_text_1')}<br><br><h4 class="text-h4">${trans('wb_dummy_text_title')}</h4>${trans('wb_dummy_text_1')}`,
      wb_align_center__checkbox__: false,
      wb_container_fluid__checkbox__: false,
      wb_image_to_right__checkbox__: false,
      wb_image_width__number__: 300,
      wb_invert_colors__checkbox__: false
    },
    images: [
      {
        slug: 'img_1',
        type: SectionImageType.IMAGE,
        name: CreatorDefaultImage.getImage(3)
      }
    ]
  }
}
