import { CreatorDefaultImage } from '@/calendesk/models/Creator/CreatorDefaultImage'
import StoreController from '@/calendesk/controllers/StoreController'

export class CreatorDefaultNewsletterBackground extends CreatorDefaultImage {
  public static getDark (number: number): string {
    return `/demo/wb/bg/dark/img${number}.jpg`
  }

  public static getLight (number: number): string {
    return `/demo/wb/bg/light/img${number}.jpg`
  }

  public static getForText1 (number: number): string {
    if (StoreController.isText1Dark()) {
      return this.getLight(number)
    }

    return this.getDark(number)
  }

  public static getForText2 (number: number): string {
    if (StoreController.isText2Dark()) {
      return this.getLight(number)
    }

    return this.getDark(number)
  }
}
