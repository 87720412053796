import { SectionsSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'

export default (): SectionConfiguration => {
  return {
    type: SectionType.SECTION,
    configuration: {
      component_id: SectionsSectionType.CTA_3,
      present_height: 150,
      wb_height__style_height__: 150,
      wb_min_height: 50,
      wb_position: 0,
      wb_buttons__array_cta_buttons__: [
        {
          title: trans('wb_dummy_text_more'),
          page_id: '',
          outlined: false,
          rounded: true,
          size: 'x-large',
          elevation: 0,
          width: 350,
          is_functional: false
        }
      ],
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: false
    },
    images: []
  }
}
