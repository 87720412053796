import { FooterSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import StoreController from '@/calendesk/controllers/StoreController'

export default (): SectionConfiguration => {
  return {
    type: SectionType.FOOTER,
    configuration: {
      component_id: FooterSectionType.FOOTER7,
      present_height: 120,
      wb_company_name__text__: StoreController.appConfiguration()?.companyName,
      wb_facebook__url__: StoreController.appConfiguration()?.facebook,
      wb_instagram__url__: StoreController.appConfiguration()?.instagram,
      wb_twitter__url__: StoreController.appConfiguration()?.twitter,
      wb_youtube__url__: StoreController.appConfiguration()?.youtube,
      wb_page_list__page_ids__: []
    },
    images: []
  }
}
