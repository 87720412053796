import { NavbarSectionType, SectionType } from '@/calendesk/models/BuilderTypes'
import SectionConfiguration from '@/calendesk/models/SectionConfiguration'
import { trans } from '@/lib/calendesk-js-library/prototypes/trans'
import { SectionImageType } from '@/calendesk/models/DTO/Response/SectionImageType'
import { BuilderSelectType } from '@/calendesk/models/BuilderSelectType'
import { RoundType } from '@/calendesk/models/RoundType'
import { CreatorDefaultLogo } from '@/calendesk/models/Creator/CreatorDefaultLogo'
import StoreController from '@/calendesk/controllers/StoreController'
import { ButtonSizeType } from '@/calendesk/models/ButtonSizeType'

export default (): SectionConfiguration => {
  return {
    type: SectionType.NAVBAR,
    configuration: {
      component_id: NavbarSectionType.FLEXIBLE_NAVBAR_1,
      wb_height__style_height__: 100,
      wb_min_height: 50,
      present_height: 100,
      wb_more_pages_button__text__: trans('wb_default_menu_title'),
      wb_company_name__text__: StoreController.appConfiguration()?.companyName,
      wb_redirect_logo__page_id__: null,
      wb_main_pages_number__number__: 3,
      wb_page_list__page_ids__: [],
      wb_login_modal__external_configuration__: 1,
      wb_sign_up_modal__external_configuration__: 2,
      wb_forgotten_password_modal__external_configuration__: 3,
      wb_forgotten_password_confirmation_modal__external_configuration__: 4,
      wb_elevation__number__: 0,
      wb_sign_in_button__cta_button__: {
        title: trans('wb_login'),
        page_id: null,
        outlined: false,
        rounded: false,
        size: 'large',
        elevation: 0,
        is_functional: true
      },
      wb_sign_up_button__cta_button__: {
        title: trans('wb_sign_up'),
        page_id: null,
        outlined: true,
        rounded: false,
        size: 'large',
        elevation: 0,
        is_functional: true
      },
      wb_buttons__array_cta_buttons__: [],
      wb_image_width__number__: 160,
      wb_image_height__number__: 60,
      wb_image_rounded__select__: {
        type: BuilderSelectType.IMAGE_ROUNDED,
        value: RoundType.ZERO
      },
      wb_button_size__select__: {
        type: BuilderSelectType.BUTTON_SIZE,
        value: ButtonSizeType.MEDIUM
      },
      wb_image_hide__checkbox__: true,
      wb_image_hide_on_mobile__checkbox__: false,
      wb_image_contain__checkbox__: true,
      wb_container_fluid__checkbox__: false,
      wb_invert_colors__checkbox__: false,
      wb_show_sign_in_button__checkbox__: true,
      wb_show_sign_up_button__checkbox__: true,
      wb_show_company_name__checkbox__: true,
      wb_show_active_button__checkbox__: true
    },
    images: [
      {
        slug: 'logo',
        type: SectionImageType.IMAGE,
        name: CreatorDefaultLogo.getForBg1()
      }
    ]
  }
}
